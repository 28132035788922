import { AxiosError } from 'axios';
import { StoreOptions } from 'vuex';

import { ApiResponse, Pagination, Picture } from '@/interfaces';
import { ProductAttribute } from './attributes';
import { Category } from './categories';

import API from '../api/api';

interface ProductBase {
  sku: string;
  alias: string;
  attributes?: ProductAttribute | null;
  categories?: Category[] | null;
  description: string;
  name: string;
  parent_id: number | null;
  price_rub: number | null,
  quantity: number | null;
  external_length: number | null;
  external_width: number | null;
  external_height: number | null;
}

export interface Product extends ProductBase {
  id: number,
  preview_picture?: Picture | null;
  pictures?: Picture[] | null;
  children?: Product[] | null;
}

export interface PicturePositions {
  id?: number;
  pictures: {
    [id: string]: { index: number };
  }
}

export interface ProductForm extends ProductBase {
  id?: number,
  category_id: number[] | null;
  preview_picture?: File | null;
  pictures?: FileList[] | null;
  children?: Product[] | null;
}

interface PicturePreviewForm {
  preview_picture: File;
}

interface PicturePreviewData extends PicturePreviewForm {
  id: number;
}

interface PicturesForm {
  pictures: FileList;
}

interface PicturesData extends PicturesForm {
  id: number;
}

interface PictureData {
  id: number;
  pictureId: number;
}

interface ProductStore {
  count: number;
  total: number;
  items: Product[];
}

const store: StoreOptions<ProductStore> = {
  actions: {
    async productIndex(context: any, { offset, limit }: Pagination) {
      const response = await API.get<ApiResponse<ProductStore>>(`products?offset=${offset}&limit=${limit}&trashed=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
    async productShow(context: any, id: number) {
      const response = await API.get<ApiResponse<ProductStore>>(`products/${id}?trashed=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
    async productStore(context: any, data: ProductForm): Promise<Product | null> {
      const response = await API.post<ApiResponse<Product>, ProductForm>('products', data, true);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Товар успешно создан');

      return response.data.data as Product;
    },
    async productUpdate(context: any, data: ProductForm): Promise<Product | null> {
      const { id } = data;

      const response = await API.put<ApiResponse<Product>, ProductForm>(`products/${id}`, data);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Товар успешно изменен');

      return response.data.data as Product;
    },
    async productRestore(context: any, id: number) {
      const response = await API.post<ApiResponse<ProductStore>, Record<string, never>>(`products/${id}/restore`, {});

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Товар успешно восстановлен');

      return response.data.data;
    },
    async productArchive(context: any, id: number) {
      const response = await API.delete<ApiResponse<ProductStore>>(`products/${id}`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Товар успешно архивирован');

      return response.data.data;
    },
    async productDelete(context: any, id: number) {
      const response = await API.delete<ApiResponse<ProductStore>>(`products/${id}?force=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Товар успешно удален');

      return response.data.data;
    },
    async productStorePicturePreview(context: any, data: PicturePreviewData): Promise<boolean> {
      const response = await API.post<ApiResponse<ProductStore>, PicturePreviewForm>(`products/${data.id}/pictures`, {
        preview_picture: data.preview_picture,
      }, true);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return false;
      }

      return response.data.success;
    },
    async productDeletePicturePreview(context: any, id: number): Promise<boolean> {
      const response = await API.delete<ApiResponse<ProductStore>>(`products/${id}/preview-picture`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return false;
      }

      context.dispatch('processSuccess', 'Изображение успешно удалено');

      return response.data.success;
    },
    async productStorePictures(context: any, data: PicturesData): Promise<boolean> {
      const response = await API.post<ApiResponse<ProductStore>, PicturesForm>(`products/${data.id}/pictures`, {
        pictures: data.pictures,
      }, true);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return false;
      }

      return response.data.success;
    },
    async productChangePositionPictures(context: any, data: PicturePositions): Promise<Product | null> {
      const { id } = data;
      const modifiedData = data;
      delete modifiedData.id;

      const response = await API.put<ApiResponse<Product>, PicturePositions>(`products/${id}/pictures`, modifiedData);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Товар успешно изменен');

      return response.data.data as Product;
    },
    async productDeletePicture(context: any, data: PictureData): Promise<boolean> {
      const response = await API.delete<ApiResponse<ProductStore>>(`products/${data.id}/pictures/${data.pictureId}`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return false;
      }

      context.dispatch('processSuccess', 'Изображение успешно удалено');

      return response.data.success;
    },
  },
};

export default store;
