import moment from 'moment';
import { AxiosError } from 'axios';
import { StoreOptions } from 'vuex';

import { ApiResponse, Pagination } from '@/interfaces';
import { Product } from './products';

import API from '../api/api';

moment.locale('ru');

export type OrderStatus =
  'created' |
  'confirmed' |
  'shipped' |
  'canceled' |
  'delivered' |
  'refunded' |
  'partially_refunded' |
  'waiting_for_cancellation' |
  'unknown';

export interface OrderStatusFormat {
  name: string;
  color: 'red' | 'green' | 'indigo' | 'gray' | 'orange';
}

export type PaymentStatus = 'payment.succeeded' | 'payment.pending' | 'payment.canceled' | 'refund.succeeded';

export type PaymentStatusFormat = ', оплачен' | ', ожидает оплаты' | ', ошибка оплаты' | ', средства возвращены' | '';

export interface OrderHistory {
  id?: number;
  read: number;
  status: OrderStatus;
  created_at: string;
  created_at_format?: string;
  payment_status: PaymentStatus | null;
}

export interface OrderedProduct extends Product {
  quantity: number;
}

export interface OrderCompany {
  address: string;
  inn: string;
  kpp: string;
  name: string;
}

export interface Order {
  admin_action_required: number;
  address: string;
  company: OrderCompany | null;
  created_at: string;
  created_at_format?: string;
  delivery_type: string;
  delivery_price?: string;
  email: string;
  id?: number;
  name: string;
  number: number;
  number_format?: string;
  order_history: OrderHistory[];
  order_price: string;
  payment_method: string;
  phone: string;
  products: OrderedProduct[];
  status: OrderStatus;
  status_format?: OrderStatusFormat;
  paid: boolean;
}

export interface ProductForm {
  [product_id: number]: {
    price_rub: number | null;
    quantity: number;
  }
}

export interface OrderForm {
  id?: number;
  products?: ProductForm;
  status?: OrderStatus;
  refund_amount?: string;
}

interface OrderStore {
  count: number;
  total: number;
  items: Order[];
}

export function formatStatus(type: OrderStatus): OrderStatusFormat {
  switch (type) {
    case 'created':
      return { color: 'red', name: 'Создан' };
    case 'confirmed':
      return { color: 'indigo', name: 'Принят в работу' };
    case 'shipped':
      return { color: 'indigo', name: 'Отгружен' };
    case 'canceled':
      return { color: 'gray', name: 'Отменен' };
    case 'delivered':
      return { color: 'green', name: 'Доставлен' };
    case 'refunded':
      return { color: 'gray', name: 'Возвращен' };
    case 'partially_refunded':
      return { color: 'gray', name: 'Возвращен частично' };
    case 'waiting_for_cancellation':
      return { color: 'orange', name: 'Ожидает отмены' };
    default:
      return { color: 'gray', name: 'Неизвестный статус' };
  }
}

export function formatPaymentStatus(type: PaymentStatus): PaymentStatusFormat {
  switch (type) {
    case 'payment.succeeded':
      return ', оплачен';
    case 'payment.pending':
      return ', ожидает оплаты';
    case 'payment.canceled':
      return ', ошибка оплаты';
    case 'refund.succeeded':
      return ', средства возвращены';
    default:
      return '';
  }
}

export function formatNumber(number: number): string {
  const parts = String(number).match(/.{1,3}/g);

  if (!parts) {
    return String(number);
  }

  return parts.join('-');
}

export function formatDateTime(dateTime: string): string {
  const time = moment(dateTime);

  if (!time) {
    return time;
  }

  return `${time.format('L')} ${time.format('LTS')}`;
}

const store: StoreOptions<OrderStore> = {
  actions: {
    async orderIndex(context: any, { offset, limit }: Pagination) {
      const response = await API.get<ApiResponse<OrderStore>>(`orders?offset=${offset}&limit=${limit}&trashed=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
    async orderShow(context: any, id: number) {
      const response = await API.get<ApiResponse<OrderStore>>(`orders/${id}?trashed=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
    async orderUpdate(context: any, data: OrderForm): Promise<Order | null> {
      const { id } = data;
      const modifiedData = data;
      delete modifiedData.id;

      const response = await API.patch<ApiResponse<Order>, OrderForm>(`orders/${id}`, modifiedData);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Заказ успешно изменен');

      return response.data.data as Order;
    },
    async orderDelete(context: any, id: number) {
      const response = await API.delete<ApiResponse<OrderStore>>(`orders/${id}`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Заказ успешно удален');

      return response.data.data;
    },
    async readNotification(context: any, historyIds: number[]) {
      for (let i = 0; i < historyIds.length; i++) {
        const response = API.get<ApiResponse<null>>(
          `order-histories/${historyIds[i]}`,
        );

        if (response instanceof AxiosError) {
          context.dispatch('processApiError', response);
          return null;
        }
      }

      return null;
    },
  },
};

export default store;
