import { AxiosError } from 'axios';
import { StoreOptions } from 'vuex';

import { ApiResponse, Pagination, Picture } from '@/interfaces';

import API from '../api/api';

export type CategoryType = 'form' | 'purpose' | 'constructor';

export interface CategoryBase {
  id?: number;
  name: string;
  type: CategoryType;
  is_on_main: string;
  deleted_at?: string;
}

export interface Category extends CategoryBase {
  picture?: Picture | null;
}

export interface CategoryForm extends CategoryBase {
  picture?: File | null;
}

interface CategoryStore {
  count: number;
  total: number;
  items: Category[];
}

const store: StoreOptions<CategoryStore> = {
  actions: {
    async categoryIndex(context: any, { offset, limit }: Pagination) {
      const response = await API.get<ApiResponse<CategoryStore>>(`categories?offset=${offset}&limit=${limit}&trashed=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
    async categoryShow(context: any, id: number) {
      const response = await API.get<ApiResponse<CategoryStore>>(`categories/${id}?trashed=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
    async categoryStore(context: any, data: CategoryForm): Promise<Category | null> {
      const response = await API.post<ApiResponse<Category>, CategoryForm>('categories', data, true);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Категория успешно создана');

      return response.data.data as Category;
    },
    async categoryUpdate(context: any, data: CategoryForm): Promise<Category | null> {
      const { id } = data;

      const response = await API.put<ApiResponse<Category>, CategoryForm>(`categories/${id}`, data);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Категория успешно изменена');

      return response.data.data as Category;
    },
    async categoryRestore(context: any, id: number) {
      const response = await API.post<ApiResponse<CategoryStore>, Record<string, never>>(`categories/${id}/restore`, {});

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Категория успешно восстановлена');

      return response.data.data;
    },
    async categoryArchive(context: any, id: number) {
      const response = await API.delete<ApiResponse<CategoryStore>>(`categories/${id}`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Категория успешно архивирована');

      return response.data.data;
    },
    async categoryDelete(context: any, id: number) {
      const response = await API.delete<ApiResponse<CategoryStore>>(`categories/${id}?force=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Категория успешно удалена');

      return response.data.data;
    },
    async categoryStorePicture(context: any, data: CategoryForm): Promise<boolean> {
      const response = await API.post<ApiResponse<CategoryStore>, CategoryForm>(`categories/${data.id}/picture`, data, true);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return false;
      }

      return response.data.success;
    },
    async categoryDeletePicture(context: any, id: number): Promise<boolean> {
      const response = await API.delete<ApiResponse<CategoryStore>>(`categories/${id}/picture`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return false;
      }

      context.dispatch('processSuccess', 'Изображение успешно удалено');

      return response.data.success;
    },
  },
};

export default store;
