import stringLength from 'string-length';
import { AxiosError } from 'axios';
import { createStore, StoreOptions } from 'vuex';

import { ApiError, ApiResponse } from '@/interfaces';

import attributes from './attributes';
import categories from './categories';
import login from './login';
import orders from './orders';
import products from './products';
import stats from './stats';
import users from './users';
import bannerSlides from './MediaCarouselSlides';

const COMFORT_READING_SPEED = 14.16;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const store: StoreOptions<any> = {
  state: () => ({
    loader: false,
    notification: {
      show: false,
      text: '',
      error: false,
      timeoutId: null,
    },
  }),
  mutations: {
    setLoader(state) {
      state.loader = true;
    },
    deleteLoader(state) {
      state.loader = false;
    },
    setNotification(state, { text, error }) {
      if (state.notification.timeoutId) {
        clearTimeout(state.notification.timeoutId);
      }

      state.notification.text = text;
      state.notification.error = error;
      state.notification.show = true;

      state.notification.timeoutId = setTimeout(() => {
        state.notification.show = false;
        state.notification.text = '';
      }, (stringLength(text) / COMFORT_READING_SPEED + 4) * 1000);
    },
    hideNotification(state) {
      if (state.notification.timeoutId) {
        clearTimeout(state.notification.timeoutId);
      }

      state.notification.show = false;
      state.notification.text = '';
    },
  },
  actions: {
    processErrors(context, errors: ApiError[] | string | null) {
      let errorMessage = '';

      if (errors) {
        if (typeof errors === 'string' || errors instanceof String) {
          errorMessage += errors;
        } else {
          errors.forEach(({ message }) => {
            errorMessage += `${message} `;
          });
        }
      }

      if (!errorMessage) {
        errorMessage = 'Ошибка соединения. Пожалуйста, повторите запрос позже.';
      }

      context.commit('setNotification', { text: errorMessage, error: true });
    },
    processApiError(context, response: AxiosError<ApiResponse<null>>) {
      if (response.response?.status === 401) {
        context.commit('setUnauthorized');
        localStorage.clear();
        context.commit('openLoginPage');
      }

      context.dispatch('processErrors', response.response?.data?.errors);
    },
    processSuccess(context, message: string) {
      context.commit('setNotification', {
        text: message.trim(),
        error: false,
      });
    },
  },
  modules: {
    attributes,
    categories,
    login,
    orders,
    products,
    stats,
    users,
    bannerSlides,
  },
};

export default createStore(store);
