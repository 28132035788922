import { AxiosError } from 'axios';
import { StoreOptions } from 'vuex';

import { ApiResponse, Pagination } from '@/interfaces';

import API from '../api/api';

export interface Attribute {
  id?: number | string;
  name: string;
  code: string;
  type: string;
}

export interface ProductAttribute {
  [code: string]: {
    name: string;
    value: string | null;
  }
}

interface AttributeStore {
  count: number;
  total: number;
  items: Attribute[];
}

const store: StoreOptions<AttributeStore> = {
  actions: {
    async attributeIndex(context: any, { offset, limit }: Pagination) {
      const response = await API.get<ApiResponse<AttributeStore>>(`attributes?offset=${offset}&limit=${limit}&trashed=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
    async attributeShow(context: any, id: number) {
      const response = await API.get<ApiResponse<AttributeStore>>(`attributes/${id}?trashed=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
    async attributeStore(context: any, data: Attribute): Promise<Attribute | null> {
      const response = await API.post<ApiResponse<Attribute>, Attribute>('attributes', data, true);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Атрибут успешно создан');

      return response.data.data as Attribute;
    },
    async attributeUpdate(context: any, data: Attribute): Promise<Attribute | null> {
      const { id } = data;

      const response = await API.put<ApiResponse<Attribute>, Attribute>(`attributes/${id}`, data);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Атрибут успешно изменен');

      return response.data.data as Attribute;
    },
    async attributeDelete(context: any, id: number) {
      const response = await API.delete<ApiResponse<AttributeStore>>(`attributes/${id}`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Атрибут успешно удален');

      return response.data.data;
    },
  },
};

export default store;
