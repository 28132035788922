import { AxiosError } from 'axios';
import { StoreOptions } from 'vuex';

import API from '@/api/api';
import router from '@/router';
import { useRoute } from 'vue-router';

import {
  ApiResponse,
  Auth,
  AuthToken,
  Me,
} from '@/interfaces';

interface State {
  user: Me,
  auth: AuthToken,
}

const login: StoreOptions<State> = {
  state: {
    user: {
      id: 0,
      email: '',
      phone: '',
      name: '',
      uuid: '',
      picture: null,
      permissions: [],
    },
    auth: {
      authorized: false,
      token: '',
    },
  },
  getters: {
    permissions(state) {
      return state.user.permissions;
    },
  },
  mutations: {
    setAuthorized(state, payload: State['auth']) {
      state.auth.authorized = payload.authorized;
      state.auth.token = payload.token;
    },
    setUser(state, payload: Me) {
      state.user.id = payload.id;
      state.user.email = payload.email;
      state.user.name = payload.name;
      state.user.uuid = payload.uuid;
      state.user.picture = payload.picture;
      state.user.permissions = payload.permissions;
    },
    setUnauthorized(state) {
      state.auth.authorized = false;
      state.auth.token = '';
    },
  },
  actions: {
    async login(context, data: Auth) {
      const response = await API.auth(data);

      if (response instanceof AxiosError) {
        context.dispatch('processErrors', response.response?.data?.errors);
      } else if (!response) {
        context.dispatch('processErrors', response);
      } else {
        context.commit('setAuthorized', response);

        const me = await this.dispatch('user');

        if (me === null || !me.permissions.includes('admin:user')) {
          context.commit('setUnauthorized');

          return;
        }

        if (localStorage.getItem('redirectedFromPath')) {
          router.push(localStorage.getItem('redirectedFromPath') as string);
        } else {
          router.push({ name: 'Dashboard' });
        }
        localStorage.removeItem('redirectedFromPath');
      }
    },
    async user(context): Promise<Me | null> {
      const me = await API.get<ApiResponse<Me>>('me');

      if (me instanceof AxiosError) {
        if (me.response?.status === 401) {
          context.commit('setUnauthorized');
          localStorage.clear();

          return null;
        }

        context.dispatch('processErrors', me.response?.data?.errors);
      } else {
        const user = me.data.data;

        if (!user?.permissions || !user?.permissions.includes('admin:user')) {
          context.dispatch('processErrors', [
            {
              code: 0,
              message: 'Недостаточно прав.',
            },
          ]);

          return null;
        }

        context.commit('setUser', user);

        // TODO: Change to route name check
        if (window.location.pathname === '/') {
          router.push({ name: 'Dashboard' });
        }

        return user;
      }

      return null;
    },
    openDashboardPage() {
      const route = useRoute();

      // TODO: Change to route name check
      if (route && window.location.pathname === '/') {
        router.push({ name: 'Dashboard' });
      }
    },
    openLoginPage(context, payload: string) {
      const route = useRoute();

      // TODO: Change to route name check
      if (route && window.location.pathname !== '/') {
        localStorage.setItem('redirectedFromPath', payload);
        router.push({ name: 'Login' });
      }
    },
    async logout(context) {
      const response = await API.delete<ApiResponse<Me>>('sessions');

      if (response instanceof AxiosError) {
        if (response.response?.status !== 401) {
          context.dispatch('processErrors', response.response?.data?.errors);
        }
      }

      context.commit('setUnauthorized', response);
      localStorage.clear();

      router.push({ name: 'Login' });
    },
  },
};

export default login;
