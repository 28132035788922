import { AxiosError } from 'axios';
import { StoreOptions } from 'vuex';

import {
  ApiResponse,
  AuthPreflight,
  Pagination,
  Picture,
} from '@/interfaces';

import API from '../api/api';

export interface Permission {
  id?: string;
  name: string;
}

interface UserBase {
  id?: number;
  email: string;
  phone: string;
  name: string;
  uuid: string;
  permissions: string[] | null;
  last_seen_at?: string | null;
  email_verified_at?: string;
}

export interface User extends UserBase {
  picture?: Picture | null;
}

export interface UserForm extends UserBase {
  picture?: File | null;
  password?: string;
  password_repeat?: string;
  token?: string;
  email_verified?: string;
}

interface UserStore {
  count: number;
  total: number;
  items: User[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const users: StoreOptions<any> = {
  actions: {
    async userIndex(context: any, { offset, limit }: Pagination) {
      const response = await API.get<ApiResponse<UserStore>>(`users?offset=${offset}&limit=${limit}&trashed=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
    async userShow(context: any, id: number) {
      const response = await API.get<ApiResponse<UserStore>>(`users/${id}?trashed=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
    async userStore(context: any, data: UserForm): Promise<User | null> {
      const formData = { ...data };
      let token = '';

      if (formData.token) {
        token = formData.token;
        delete formData.token;
      }

      const response = await API.post<ApiResponse<User>, UserForm>('users', data, true, token);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Пользователь успешно зарегистрирован');

      return response.data.data as User;
    },
    async userUpdate(context: any, data: UserForm): Promise<User | null> {
      const formData = { ...data };
      let token = '';

      if (formData.token) {
        token = formData.token;
        delete formData.token;
      }

      const { id } = formData;

      const response = await API.put<ApiResponse<User>, UserForm>(`users/${id}`, formData, token);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Пользователь успешно изменен');

      return response.data.data as User;
    },
    async userDelete(context: any, id: number) {
      const response = await API.delete<ApiResponse<UserStore>>(`users/${id}?force=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Пользователь успешно удален');

      return response.data.data;
    },
    async userStorePicture(context: any, data: UserForm): Promise<boolean> {
      const response = await API.post<ApiResponse<UserStore>, UserForm>(`users/${data.id}/picture`, data, true);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return false;
      }

      return response.data.success;
    },
    async userDeletePicture(context: any, id: number): Promise<boolean> {
      const response = await API.delete<ApiResponse<UserStore>>(`users/${id}/picture`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return false;
      }

      context.dispatch('processSuccess', 'Изображение успешно удалено');

      return response.data.success;
    },
    async getPasswordSession(context: any): Promise<AuthPreflight | null> {
      const response = await API.get<ApiResponse<AuthPreflight>>('sessions');

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
  },
};

export default users;
