import JSEncrypt from 'jsencrypt';
import axios, { AxiosError, AxiosRequestHeaders } from 'axios';
import cryptoRandomString from 'crypto-random-string';
import { serialize } from 'object-to-formdata';

import BASE_URL from '@/api/api.baseurl';
import { IRequest, IResponse } from '@/api/api.interfaces';

import {
  AuthPreflight,
  ApiResponse,
  Auth,
  Token,
} from '@/interfaces';

function setToken(token: string) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

const tokenFromStorage = localStorage.getItem('token');

if (tokenFromStorage) {
  setToken(tokenFromStorage);
}

export default {
  async auth(data: IRequest) {
    const response = (await this.get<ApiResponse<AuthPreflight>>('sessions'));
    if (!(response instanceof AxiosError)) {
      if (response.data.data && response.data.success) {
        setToken(response.data.data.token);

        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(response.data.data.public_key);

        const password = encrypt.encrypt(data.password) as string;

        const modifiedData = {
          email: data.email,
          password,
        };

        const responsePost = await this.post<ApiResponse<Token>, Auth>('sessions', modifiedData);

        if (!(responsePost instanceof AxiosError)) {
          if (responsePost.data.data && responsePost.data.success) {
            setToken(responsePost.data.data.token);
            localStorage.setItem('token', responsePost.data.data.token);

            return {
              authorized: true,
              token: responsePost.data.data.token,
            };
          }
        }

        return responsePost;
      }

      return response;
    }

    return false;
  },
  async get<T>(url: string) {
    try {
      return await axios.get<T>(url);
    } catch (error) {
      return error as AxiosError<IResponse>;
    }
  },
  async post<T, E>(url: string, data: E, multipart = false, token = '') {
    let formData: FormData | E;

    if (multipart && data && Object.values(data).length) {
      formData = serialize(data);
    } else if (!data || !Object.values(data).length) {
      formData = {} as E;
    } else {
      formData = data;
    }

    const key = cryptoRandomString({ length: 16, type: 'alphanumeric' });

    const headers: AxiosRequestHeaders = {
      'Idempotency-Key': key,
      'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    try {
      return await axios.post<T>(url, formData, { headers });
    } catch (error) {
      return error as AxiosError<IResponse>;
    }
  },
  async put<T, E>(url: string, data: E, token = '') {
    const key = cryptoRandomString({ length: 16, type: 'alphanumeric' });

    const headers: AxiosRequestHeaders = {
      'Idempotency-Key': key,
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    try {
      return await axios.put<T>(url, data, { headers });
    } catch (error) {
      return error as AxiosError<IResponse>;
    }
  },
  async patch<T, E>(url: string, data: E, token = '') {
    const key = cryptoRandomString({ length: 16, type: 'alphanumeric' });

    const headers: AxiosRequestHeaders = {
      'Idempotency-Key': key,
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    try {
      return await axios.patch<T>(url, data, { headers });
    } catch (error) {
      return error as AxiosError<IResponse>;
    }
  },
  async delete<T>(url: string) {
    try {
      return await axios.delete<T>(url);
    } catch (error) {
      return error as AxiosError<IResponse>;
    }
  },
};
