import {
  ApiResponse,
  Pagination,
  Picture,
} from '@/interfaces';
import { StoreOptions } from 'vuex';
import API from '@/api/api';
import { AxiosError } from 'axios';
import { Order, OrderForm } from '@/store/orders';
import { Product } from '@/store/products';

interface SlidePosition {
  id?: number;
  below_id: number;
}

interface PictureForm {
  mobile_picture: File;
  desktop_picture: File;
}

interface PictureData extends PictureForm {
  id: number;
}

interface VideoForm {
  mobile_video: File;
  desktop_video: File;
}

interface VideoData extends VideoForm {
  id: number;
}

export interface SlideBase {
  id?: number;
  title: string;
  subtitle: string;
  button_text: string;
  button_link: string;
  index: number;
  content_position: string;
}

export interface Slide extends SlideBase {
  desktop_picture: Picture | null;
  desktop_video: Picture | null;
  mobile_picture: Picture | null;
  mobile_video: Picture | null;
}

export interface SlideForm extends SlideBase {
  desktop_picture?: File | null;
  desktop_video?: File | null;
  mobile_picture?: File | null;
  mobile_video?: File | null;
}

interface SlideStore {
  count: number;
  total: number;
  items: Slide[];
}

const store: StoreOptions<SlideStore> = {
  actions: {
    async slidesIndex(context: any, { offset, limit }: Pagination) {
      const response = await API.get<ApiResponse<SlideStore>>(`banners?offset=${offset}&limit=${limit}&trashed=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
    async slideShow(context: any, id: number) {
      const response = await API.get<ApiResponse<SlideStore>>(`banners/${id}?trashed=true`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      return response.data.data;
    },
    async slideStore(context: any, data: SlideForm): Promise<Slide | null> {
      const response = await API.post<ApiResponse<Slide>, SlideForm>('banners', data, true);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Слайд успешно создан');

      return response.data.data as Slide;
    },
    async slideUpdate(context: any, data: SlideForm): Promise<Order | null> {
      const { id } = data;
      const modifiedData = data;
      delete modifiedData.id;

      const response = await API.put<ApiResponse<Order>, OrderForm>(`banners/${id}`, modifiedData);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Слайд успешно изменен');

      return response.data.data as Order;
    },
    async slideDelete(context: any, id: number) {
      const response = await API.delete<ApiResponse<SlideStore>>(`banners/${id}`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Слайд успешно удален');

      return response.data.data;
    },
    async slideStorePictures(context: any, data: PictureData): Promise<boolean> {
      const response = await API.post<ApiResponse<SlideStore>, PictureForm>(`banners/${data.id}/pictures`, {
        mobile_picture: data.mobile_picture,
        desktop_picture: data.desktop_picture,
      }, true);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return false;
      }

      return response.data.success;
    },
    async slideStoreVideos(context: any, data: VideoData): Promise<boolean> {
      const response = await API.post<ApiResponse<SlideStore>, VideoForm>(`banners/${data.id}/videos`, {
        mobile_video: data.mobile_video,
        desktop_video: data.desktop_video,
      }, true);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return false;
      }

      return response.data.success;
    },
    async slideDeleteMobileVideo(context: any, id: number): Promise<boolean> {
      const response = await API.delete<ApiResponse<SlideStore>>(`banners/${id}/mobile-video`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return false;
      }

      context.dispatch('processSuccess', 'Видео успешно удалено');

      return response.data.success;
    },
    async slideDeleteDesktopVideo(context: any, id: number): Promise<boolean> {
      const response = await API.delete<ApiResponse<SlideStore>>(`banners/${id}/desktop-video`);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return false;
      }

      context.dispatch('processSuccess', 'Видео успешно удалено');

      return response.data.success;
    },
    async changeSlidePosition(context: any, data: SlidePosition): Promise<Product | null> {
      const { id } = data;
      const modifiedData = data;
      delete modifiedData.id;

      const response = await API.patch<ApiResponse<Product>, SlidePosition>(`banners/${id}/order`, modifiedData);

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      context.dispatch('processSuccess', 'Позиция успешно изменена');

      return response.data.data as Product;
    },
  },
};

export default store;
