export const hashCode = (name: string) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    const character = name.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + character;
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

export const getModulus = (num: number, max: number) => num % max;

export const getDigit = (number: number, ntn: number) => Math.floor((number / 10 ** ntn) % 10);

export const getBoolean = (number: number, ntn: number) => (!((getDigit(number, ntn)) % 2));

export const getAngle = (x: number, y: number) => (
  (Math.atan2(y, x) * 180) / Math.PI
);

export const getUnit = (number: number, range: number, index: number) => {
  const value = number % range;

  if (index && ((getDigit(number, index) % 2) === 0)) {
    return -value;
  } return value;
};

export const getRandomColor = (number: number, colors: string[], range: number) => colors[(number) % range];

const HEX_PATTERN = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

export const getContrast = (hexcolor: string) => {
  const match = hexcolor.match(HEX_PATTERN);

  if (!match) {
    throw new Error(`Invalid hex color value: ${hexcolor}`);
  }

  const [, r, g, b] = match.map((color) => parseInt(color, 16));

  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  return yiq >= 128 ? '#000000' : '#FFFFFF';
};

export const numToPrice = (target: number | string, delivery?: number, digits = true, currency = 'ru') => {
  let dotReplace: string | string[];

  if (digits) {
    dotReplace = (`${target}`).replace(/\./, ',');
  } else {
    dotReplace = (`${target}`).replace(/\.\d*/, '');
  }

  if (delivery) {
    dotReplace = dotReplace.split(',');
    dotReplace[0] = `${500 + +dotReplace[0]}`;
    dotReplace = dotReplace.join(',');
  }

  return (`${dotReplace}`).replaceAll(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + (currency === 'ru' ? ' ₽' : ' $');
};
