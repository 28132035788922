import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import './modules';

import App from './App.vue';

import router from './router';
import store from './store';

import './assets/tailwind.css';

const app = createApp(App);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.VUE_APP_API_DOMAIN, /^\//],
    }),
  ],
  tracesSampleRate: 0.1,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  logErrors: true,
  release: process.env.VUE_APP_RELEASE,
});

app.use(store);
app.use(router);

app.mount('#app');
