import { StoreOptions } from 'vuex';

import { ApiResponse } from '@/interfaces';
import { AxiosError } from 'axios';

import API from '../api/api';

export interface Stats {
  total_users: number;
  total_products: number;
  total_orders: number;
  users_created_this_week: number;
  products_created_this_week: number;
  orders_created_this_week: number;
  orders_requiring_action: number;
}

const store: StoreOptions<any> = {
  state: {
    order_history_unread_notifications: 0,
  },
  actions: {
    async statsIndex(state, context: any) {
      const response = await API.get<ApiResponse<Stats>>('stats');

      if (response instanceof AxiosError) {
        context.dispatch('processApiError', response);
        return null;
      }

      if (response.data?.data) {
        state.state.orders_requiring_action = response.data.data.orders_requiring_action;
      }

      return response.data.data;
    },
  },
};

export default store;
