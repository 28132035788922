import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const DEFAULT_TITLE = 'Панель управления';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    meta: {
      layout: 'LoginLayout',
    },
    component: () => import('../pages/LoginPage.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      layout: 'MainLayout',
    },
    component: () => import('../pages/Modules/ModuleIndex.vue'),
  },
  {
    path: '/modules',
    name: 'Modules',
    redirect: '/dashboard',
  },
  {
    path: '/modules/:moduleName',
    name: 'ModuleIndex',
    meta: {
      layout: 'MainLayout',
    },
    component: () => import('../pages/Modules/ModuleIndex.vue'),
  },
  {
    path: '/modules/:moduleName/create',
    name: 'ModuleCreate',
    meta: {
      layout: 'MainLayout',
    },
    component: () => import('../pages/Modules/ModuleCreate.vue'),
  },
  {
    path: '/modules/:moduleName/:id/edit',
    name: 'ModuleEdit',
    meta: {
      layout: 'MainLayout',
    },
    component: () => import('../pages/Modules/ModuleCreate.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      title: 'Страница не найдена',
      layout: 'MainLayout',
    },
    component: () => import('../pages/NotFoundPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }

    // Always scroll to the top
    return { top: 0 };
  },
});

router.afterEach((to) => {
  document.title = to.meta.title as string || DEFAULT_TITLE;
});

export default router;
